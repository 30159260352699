import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../Constant/slugs';
import '../styles.css'
import { sideBarImage } from '../Constant/api';
const SideBarBlock = ({
  allSlug, newSlug, location,
  Title, icon, setExpand, Expand,
  setMenuExpand
}) => {
  return (
    <div>
      <div className={location.pathname == allSlug ? "row active-sidebar" : "row passive-sidebar"}

      >
        <Link className={"Link col-10"} to={allSlug}>
          <div className={location.pathname == allSlug ? "active-sidebar-font" : "passive-sidebar-font"}
            onClick={() => {
              setMenuExpand(false)
            }}
          >
            &nbsp; &nbsp;{Title}
          </div>
        </Link>
        {newSlug !== "" &&
          <div className="col-1" style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpand(!Expand)
            }}>
            <i className={Expand ? "fa fa-minus" : "fa fa-plus"} style={{ fontSize: "10px" }} />
          </div>
        }
      </div>
      {Expand && newSlug !== "" &&
        <>
          <Link className={location.pathname == allSlug ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={allSlug}
          >
            <div className={location.pathname == allSlug ? "Link active-sidebar-font" : "Link passive-sidebar-font"}>
              All {Title}
            </div>
          </Link>
          <Link className={location.pathname == `/${newSlug}` ? "Link active-sidebar" : "Link passive-sidebar"}
            style={{ paddingLeft: "40px" }}
            to={newSlug}
          >
            <div className={location.pathname == `/newSlug` ? "active-sidebar-font" : "passive-sidebar-font"}>
              Add New {Title.slice(0, -1)}
            </div>
          </Link>
        </>
      }
    </div>
  )
}
export default function SideBar({ setMenuExpand }) {
  const LoginUser = useSelector((state) => state.LoginState);
  const [Menu, setMenu] = React.useState(false)
  const location = useLocation()

  const [ExamExpand, setExamExpand] = React.useState(false)
  const [DemoExamExpand, setDemoExamExpand] = React.useState(false)
  const [DailyExamExpand, setDailyExamExpand] = React.useState(false)
  const [ChapterWiseExamExpand, setChapterWiseExamExpand] = React.useState(false)
  const [BookExpand, setBookExpand] = React.useState(false)
  const [StudentExpand, setStudentExpand] = React.useState(false)
  const [BatchExpand, setBatchExpand] = React.useState(false)
  const [DailyResultsExpand, setDailyResultsExpand] = React.useState(false)
  const [ExamResultsExpand, setExamResultsExpand] = React.useState(false)

  return (
    <div>
      <div className="small-display-none">
        <div style={{ justifyContent: 'center', display: 'flex', padding: "30px", borderBottom: "1px solid #eee" }}>
          <img
            src={sideBarImage}
            style={{ width: "200px", height: "100px", objectFit: 'contain' }}
          />
        </div>
      </div>

      <div className="small-display-block">
        <div className="row passive-sidebar" onClick={() => { setMenuExpand(false) }}>
          <div className={"passive-sidebar-font"}>
            <i className="fa fa-close" style={{ fontSize: "16px" }} />&nbsp; &nbsp;Close
          </div>
        </div>
      </div>
      {/* Home */}
      {/* {LoginUser.userRole} */}
      {LoginUser.userRole == "author" &&
        <>
          {/* <div className="row" className={location.pathname == "/" ? "active-sidebar" : "passive-sidebar"}>
            <Link className={"Link col-10"} to={`/`}>
              <div className={location.pathname == "/" ? "active-sidebar-font" : "passive-sidebar-font"}>
                <i className="fa fa-home" style={{ fontSize: "16px" }} />&nbsp; &nbsp;Home
              </div>
            </Link>
          </div> */}
          <SideBarBlock
            allSlug={slugs.all_exam} newSlug={slugs.new_exam}
            location={location} Title={"Exams"}
            icon={""}
            Expand={ExamExpand}
            setExpand={setExamExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_demo_exam} newSlug={slugs.new_demo_exam}
            location={location} Title={"Demo Exams"}
            icon={""}
            setExpand={setDemoExamExpand}
            Expand={DemoExamExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_daily_exam} newSlug={slugs.new_daily_exam}
            location={location} Title={"All Batch Exams"}
            icon={""}
            setExpand={setDailyExamExpand} Expand={DailyExamExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_chapters} newSlug={slugs.new_chapter_wise_exam}
            location={location} Title={"Chapter Exams"}
            icon={""}
            setExpand={setChapterWiseExamExpand} Expand={ChapterWiseExamExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_books} newSlug={slugs.new_book}
            location={location} Title={"All Books"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_daily_results} newSlug={""}
            location={location} Title={"Batch Results"}
            icon={""}
            setExpand={setDailyExamExpand} Expand={BookExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_results} newSlug={""}
            location={location} Title={"Exam Results"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
            setMenuExpand={setMenuExpand}
          />
           <SideBarBlock
            allSlug={slugs.all_chapter_results} newSlug={""}
            location={location} Title={"Chapter Results"}
            icon={""}
            setExpand={setBookExpand} Expand={BookExpand}
            setMenuExpand={setMenuExpand}
          />
          <SideBarBlock
            allSlug={slugs.all_students} newSlug={slugs.new_student}
            location={location} Title={"Student"}
            icon={""}
            setExpand={setStudentExpand} Expand={StudentExpand}
            setMenuExpand={setMenuExpand}
          />
           <SideBarBlock
            allSlug={slugs.all_batch} newSlug={slugs.new_batch}
            location={location} Title={"Batch"}
            icon={""}
            setExpand={setBatchExpand} Expand={BatchExpand}
            setMenuExpand={setMenuExpand}
          />
          <div className="row active-sidebar">
            Student Panel
          </div>
        </>
      }
      <SideBarBlock
        allSlug={slugs.exam} newSlug={""}
        location={location} Title={"All Exam "}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.demo_exam} newSlug={""}
        location={location} Title={"Demo Exam"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.chapter_wise_exam} newSlug={""}
        location={location} Title={"Chapters Exam"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.daily_exam} newSlug={""}
        location={location} Title={"My Batch Exam"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.exam_result_list} newSlug={""}
        location={location} Title={"All Exam Results"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
       <SideBarBlock
        allSlug={slugs.chapter_wise_exam_result_list} newSlug={""}
        location={location} Title={"Chapter Results"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={slugs.daily_exam_result_list} newSlug={""}
        location={location} Title={"My Batch Results"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
      <SideBarBlock
        allSlug={"/profile"} newSlug={""}
        location={location} Title={"Profile"}
        icon={""}
        setExpand={setBookExpand} Expand={BookExpand}
        setMenuExpand={setMenuExpand}
      />
    </div>
  )
}