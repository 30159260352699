import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(
    db_slug == "demo_exam" ?
      JSON.parse(FetchData.meta.exam_question_text) :
      JSON.parse(FetchData.meta.exam_question_block_text)
  );
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const [reload, setReload] = React.useState(false)
  const [slugValue, setSlugValue] = React.useState(FetchData.slug)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data;
    if (slug == "daily_exam")
      data = {
        "title": Title,
        "status": "publish",
        "slug": slugValue,
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "batch_id": batchID,
          "batch_name": batchName,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else if (slug == "demo_exam")
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }
    else
      data = {
        "title": Title,
        "status": "publish",
        "meta": {
          "exam_question_block_text": JSON.stringify(QuestionsData),
          "exam_time": Time,
          "exam_details": Details,
          "available_unavailable": Available,
          "featured_image": FeaturedImage,
          "media_non_repeat": AudioSingle,
          "exam_type": ExamType
        }
      }

    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])

  useEffect(() => {
    if (db_slug == "daily_exam")
      BatchList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const StudentExportRef = React.useRef(null);
  const StudentExportPrint = useReactToPrint({
    content: () => StudentExportRef.current,
    pageStyle: { margin: 0 }


  })
  const StudentExportRef1 = React.useRef(null);
  const StudentExportPrint1 = useReactToPrint({
    content: () => StudentExportRef1.current,
    pageStyle: { margin: 0 }


  })
  const StudentExportRef2 = React.useRef(null);
  const StudentExportPrint2 = useReactToPrint({
    content: () => StudentExportRef2.current,
    pageStyle: { margin: 0 }


  })
  const StudentExportRef3 = React.useRef(null);
  const StudentExportPrint3 = useReactToPrint({
    content: () => StudentExportRef3.current,
    pageStyle: { margin: 0 }
  })


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>

          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              
              {/* <tr>
                <td className="col-3">Exam Slug</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr> */}

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {Database.exam_type.map((item, index) => (
                      <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={setExamType} Data={ExamType} DataValue={item} />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
              {webSlug == "/daily-exams/" &&
                <>
                  <tr>
                    <td className="col-3">Batch Id</td>
                    <td className="col-6"
                      onClick={() => {
                        setBatchModal(!batchModal)
                      }}
                    >
                      <div className="row">
                        <div className="col-8">
                          <div className="input-common">
                            {batchID}&nbsp;
                          </div>
                        </div>
                        <div className="col-4"
                          onClick={() => {
                            setBatchID("")
                            setBatchName("")
                          }}
                        >
                          Clear
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-3">Batch Name</td>
                    <td className="col-6"
                      onClick={() => {
                        setBatchModal(!batchModal)
                      }}
                    >
                      <div className="input-common">
                        {batchName}&nbsp;
                      </div>
                      {/* <input className="input-common"
                    value={batchName} placeholder="Batch Name"
                    onChange={(event) => { setBatchName(event.target.value) }}
                  /> */}
                      {batchModal &&
                        <section className="modal" >
                          <div className="row">
                            <div className="col-md-3 col-1"></div>
                            <div className="col-md-6 col-10  shadow bg-white">
                              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                                  onClick={() => {
                                    setBatchModal(!batchModal)
                                  }}
                                >X</div>
                                <table>
                                  <tbody>
                                    {batchList.map((item, index) => (
                                      <tr className="row bg-white shadow" key={index}
                                        style={{ cursor: "pointer", border: "1px solid #999" }}
                                        onClick={() => {
                                          setBatchID(`${item.id}`)
                                          setBatchName(item.title.rendered)

                                          setBatchModal(!batchModal)
                                        }}
                                      >
                                        <td className="col-1">
                                          {batchID !== item.id ?
                                            <i className="fa fa-square-o" />
                                            :
                                            <i className="fa fa-check-square-o" />
                                          }
                                        </td>
                                        <td className="col-2" >
                                          {item.id}
                                        </td>
                                        <td className="col-3">
                                          {item.title.rendered}
                                        </td>
                                        <td className="col-3">
                                          {item.meta.batch_start_date}
                                        </td>
                                        <td className="col-3">
                                          {item.meta.batch_end_date}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </section>
                      }
                    </td>
                  </tr>
                </>
              }
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              Table
            </div>
          </div>
          {(db_slug == slugs.db_slug_daily_exam || db_slug == slugs.db_slug_chapter_wise_exam) &&
            <div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      setQuestionsEditData({
                        "question_number": `${Object.keys(QuestionsData).length + 1}`,
                        "question": "",
                        "question_description": "",
                        "question_description_type": "text",
                        "question_media": "",
                        "question_media_type": "none",
                        "option_1": "",
                        "option_2": "",
                        "option_3": "",
                        "option_4": "",
                        "answer_description": "",
                        "correct_answer": "option 1",
                        "answer_media_type": "text",
                      })
                      setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                      setQuestionsEditMode(true)
                    }}
                  >
                    Add Question
                  </div>
                </div>
              </div>
            </div>
          }
          {QuestionsBlock ?
            <div>
              <div className="row m-4">
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index < Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    {Object.values(QuestionsData).map((item, index) => {
                      if (index >= Object.values(QuestionsData).length / 2)
                        return (
                          <div key={index} className="col-2 p-2 center"
                            style={{
                              margin: "5px",
                              backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                              fontWeight: "bold",
                              border: "2px solid black",
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setQuestionsEditData(item)
                              setQuestionsEditKey(Object.keys(QuestionsData)[index])
                              setQuestionsEditMode(true)
                            }}
                          >
                            {item.question_number}
                          </div>
                        )
                    })}
                  </div>
                </div>
              </div>
            </div>
            :
            <div style={{ overflowX: "scroll" }}>
              <div
                className="row"
                onClick={() => {

                }}
              >
                <div style={{ width: "5%", display: "inline", textAlign: "center" }}>QN</div>
                <div style={{ width: "30%" }}>Question</div>
                <div style={{ width: "15%", }}>Option 1</div>
                <div style={{ width: "15%", }}>Option 2</div>
                <div style={{ width: "15%", }}>Option 3</div>
                <div style={{ width: "15%", }}>Option 4</div>
                <div style={{ width: "5%", }}>Correct</div>
              </div>
              {Object.values(QuestionsData).map((item, index) => (
                <div key={index} style={{ cursor: "pointer", backgroundColor: index % 2 == 0 ? "#fff" : "#eee", padding: "5px 0" }}
                  className="row"
                  onClick={() => {
                    setQuestionsEditData(item)
                    setQuestionsEditKey(Object.keys(QuestionsData)[index])
                    setQuestionsEditMode(true)
                  }}
                >
                  <div style={{ width: "5%", display: "inline", textAlign: "center" }}>{item.question_number}</div>
                  <div style={{ width: "30%" }}>{item.question}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_1 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_2 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_3 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_4 : item.answer_media_type}</div>
                  <div style={{ width: "5%", }}>{item.correct_answer.replace("option ", "")}</div>
                </div>
              ))}
            </div>
          }
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Make a copy to:</center></h4>
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Batch Wise Exam"} />
          {db_slug == "daily_exam" &&
            <>
              {Object.keys(QuestionsData).length == 40 &&
                <>
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"All Exam"} />
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Demo Exam"} />
                </>
              }
            </>
          }
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (CopyTo == "Batch Wise Exam")
                    EditInfo("", slugs.db_slug_daily_exam, slugs.daily_exam_details)
                  else if (CopyTo == "All Exam")
                    EditInfo("", slugs.db_slug_paid_exam, slugs.exam_details)
                  else
                    EditInfo("", slugs.db_slug_demo_exam, slugs.demo_exam_details)
                }}
              >
                Make a copy
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Make PDF:</center></h4>


          <div className="row center m-2">
            {/* <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  StudentExportPrint()
                }}
              >
                Convert to pdf
              </div>
            </div>
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  StudentExportPrint1()
                }}
              >
                Convert to pdf 2
              </div>
            </div>
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  StudentExportPrint2()
                }}
              >
                Convert to pdf 3
              </div>
            </div> */}
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  StudentExportPrint3()
                }}
              >
                Convert to pdf 4
              </div>
            </div>
          </div>
        </div>
      </div>


      <div style={{ margin: "20px", display: "none" }}>
        <div ref={StudentExportRef} style={{ margin: "20px" }}>


          <div className="row">
            {/* <div className="col-1"></div> */}
            <div className="col-12 form-view mb-3" style={{ padding: "20px" }}>


              <div style={{ overflowX: "scroll", pageBreakBefore: "auto", }}>


                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}
                    onClick={() => {
                    }}
                  >
                    <div>
                      <div
                        className="row"
                        style={{
                          border: "1px solid #999",
                          margin: "5px",
                          padding: 0
                        }}>
                        <div className="col-md-8" >
                          <div style={{
                            // margin: "3px", padding: "3px",
                            // border: "1px solid #999",
                          }}
                          >
                            <div
                              style={{ padding: "5px 0 0 0" }}
                            >
                              <h3 style={{ fontSize: 12, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question}
                              </h3>
                              <h3 style={{ fontSize: 12, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question_number}.
                              </h3>
                            </div>


                            <div className="question-description ">
                              {oneQuestionData.question_description_type == "text" &&
                                <div style={{
                                  fontSize: "12px",
                                  padding: "0 0 15px"
                                }}>
                                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              }
                              {oneQuestionData.question_description_type == "image" &&
                                <>
                                  <div style={{
                                    padding: "2px", textAlign: "center",
                                    // width: "392px",
                                    // width: "100%",
                                  }}
                                    onClick={() => {
                                    }}
                                  >
                                    <img
                                      src={oneQuestionData.question_description}
                                      style={{
                                        height: "150px",
                                        width: "392px",
                                        objectFit: 'contain',
                                        padding: "2px",
                                        border: "0.5px solid #000",
                                      }}
                                    />
                                  </div>
                                </>
                              }
                              {oneQuestionData.question_description_type == "audio" &&
                                <div>
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <div style={{ position: "absolute" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                        <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                                      </div>
                                    </div>
                                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </div>


                                </div>
                              }
                            </div>


                            {oneQuestionData.question_media !== "" &&
                              <div className="question-description col-10">
                                {oneQuestionData.question_media_type == "text" &&
                                  <div style={{
                                    border: "0.5px solid #000",
                                    padding: "5px ",
                                    justifyContent: 'center',
                                    display: "flex"


                                  }}>
                                    <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                  </div>
                                }


                                {oneQuestionData.question_media_type == "image" &&
                                  <div style={{ textAlign: "center" }}
                                    onClick={() => {
                                    }}
                                  >
                                    <img
                                      src={oneQuestionData.question_media}
                                      style={{
                                        height: "150px",
                                        width: "392px",
                                        // width: "100%",
                                        padding: "2px",
                                        objectFit: 'contain',
                                        border: "0.5px solid #000"
                                      }}
                                    />
                                  </div>
                                }
                                {oneQuestionData.question_media_type == "audio" &&
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <div style={{ position: "absolute" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                        <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                                      </div>
                                    </div>
                                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                      style={{ width: "50px", height: "50px" }}
                                    />


                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </div>


                        <div className="col-md-4" style={{ padding: "0" }}>
                          <div
                            // className="row"
                            style={{
                              border: "1px solid #999",
                              // margin: "10px"
                            }} >
                            <AnswerBlock index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} />


                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ width: "5%", display: "inline", textAlign: "center" }}>{item.question_number}</div>
<div style={{ width: "30%" }}>{item.question}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_1 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_2 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_3 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_4 : item.answer_media_type}</div>
<div style={{ width: "5%", }}>{item.correct_answer.replace("option ", "")}</div> */}


                  </section>
                ))}


                {/* <a href="https://api.epsexam.com/wp-content/uploads/2023/03/ubt-cbt-eps-1-1.jpeg" download="w3logo">
<img src="https://api.epsexam.com/wp-content/uploads/2023/03/ubt-cbt-eps-1-1.jpeg" alt="W3Schools" width="104" height="142"/>
</a> */}




                <div style={{ pageBreakBefore: "always", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  {Object.values(QuestionsData).map((oneQuestionData, index) => (
                    <section key={index} style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}
                      onClick={() => {
                      }}
                    >
                      {(oneQuestionData.question_description_type == "audio" ||
                        oneQuestionData.question_media_type == "audio") &&
                        <div>
                          <div style={{ cursor: "pointer" }} onClick={() => {
                          }}>
                            <u><b>Question No {oneQuestionData.question_number}.</b></u>
                            <br />
                            <u><b>Question Audio Link</b></u>
                            <br />
                            {oneQuestionData.question_description.includes(".mp3") &&
                              // oneQuestionData.question_description
                              <div style={{ cursor: "pointer" }} onClick={() => {
                              }}>
                                <a href={oneQuestionData.question_description} download={`${Title}-${oneQuestionData.question_number}`}>
                                  <div style={{ position: "absolute" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                      <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                    </div>
                                  </div>
                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </a>
                              </div>
                            }
                            {oneQuestionData.question_media.includes(".mp3") &&
                              // oneQuestionData.question_media
                              <div style={{ cursor: "pointer" }} onClick={() => {
                              }}>
                                <a href={oneQuestionData.question_media} download={`${Title}-${oneQuestionData.question_number}`}>
                                  <div style={{ position: "absolute" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                      <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                    </div>
                                  </div>
                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </a>
                              </div>
                            }




                            {oneQuestionData.answer_media_type == "audio" &&
                              <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                                <br />
                                <u><b>Answer Audio Link</b></u>
                                <br />
                                <div style={{ display: "flex" }}>
                                  A.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_1} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  B. <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_2} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  C.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_3} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  D.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_4} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                </div>
                              </div>
                            }


                          </div>
                        </div>
                      }
                    </section>
                  ))}
                </div>
                <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  <br />
                  <u><b>Answers</b></u>
                  <br />
                  <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
                    <div className="row" >
                      {Object.values(QuestionsData).map((oneQuestionData, index) => (
                        <section key={index} style={{ width: "10%", border: "1px solid black", }}>
                          <div className="row">
                            <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
                            <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>




      <div style={{ margin: "20px", display: "none" }}>
        <div ref={StudentExportRef1} style={{ margin: "20px" }}>
          <h2>{Title}</h2>
          <div className="row">
            {/* <div className="col-1"></div> */}
            <div className="col-12 form-view mb-3" style={{ padding: "20px" }}>


              <div style={{ overflowX: "scroll", pageBreakBefore: "auto", }}>


                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}
                    onClick={() => {
                    }}
                  >
                    <div>
                      <div
                        // className="row"
                        style={{
                          border: "1px solid #999",
                          margin: "5px",
                          padding: 0
                        }}>
                        <div className="col-md-12" >
                          <div style={{
                            // margin: "3px", padding: "3px",
                            // border: "1px solid #999",
                          }}
                          >
                            <div
                              style={{ padding: "5px 0 0 0" }}
                            >
                              <h3 style={{ fontSize: 12, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question}
                              </h3>
                              <h3 style={{ fontSize: 12, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question_number}.
                              </h3>
                            </div>


                            <div className="question-description ">
                              {oneQuestionData.question_description_type == "text" &&
                                <div style={{
                                  fontSize: "12px",
                                  padding: "0 0 15px"
                                }}>
                                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              }
                              {oneQuestionData.question_description_type == "image" &&
                                <>
                                  <div style={{
                                    padding: "2px", textAlign: "center",
                                    // width: "392px",
                                    // width: "100%",
                                  }}
                                    onClick={() => {
                                    }}
                                  >
                                    <img
                                      src={oneQuestionData.question_description}
                                      style={{
                                        height: "150px",
                                        width: "392px",
                                        objectFit: 'contain',
                                        padding: "2px",
                                        border: "0.5px solid #000",
                                      }}
                                    />
                                  </div>
                                </>
                              }
                              {oneQuestionData.question_description_type == "audio" &&
                                <div>
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <div style={{ position: "absolute" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                        <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                                      </div>
                                    </div>
                                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  </div>


                                </div>
                              }
                            </div>


                            {oneQuestionData.question_media !== "" &&
                              <div className="question-description col-10">
                                {oneQuestionData.question_media_type == "text" &&
                                  <div style={{
                                    border: "0.5px solid #000",
                                    padding: "5px ",
                                    justifyContent: 'center',
                                    display: "flex"


                                  }}>
                                    <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                  </div>
                                }


                                {oneQuestionData.question_media_type == "image" &&
                                  <div style={{ textAlign: "center" }}
                                    onClick={() => {
                                    }}
                                  >
                                    <img
                                      src={oneQuestionData.question_media}
                                      style={{
                                        height: "150px",
                                        width: "392px",
                                        // width: "100%",
                                        padding: "2px",
                                        objectFit: 'contain',
                                        border: "0.5px solid #000"
                                      }}
                                    />
                                  </div>
                                }
                                {oneQuestionData.question_media_type == "audio" &&
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <div style={{ position: "absolute" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                        <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                                      </div>
                                    </div>
                                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                      style={{ width: "50px", height: "50px" }}
                                    />


                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </div>


                        <div className="col-md-12" style={{ padding: "0" }}>
                          <div
                            className="row"
                            style={{
                              // border: "1px solid #999",
                              margin: "10px"
                            }} >
                            <AnswerBlock2 index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock2 index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock2 index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock2 index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} />


                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ width: "5%", display: "inline", textAlign: "center" }}>{item.question_number}</div>
<div style={{ width: "30%" }}>{item.question}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_1 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_2 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_3 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_4 : item.answer_media_type}</div>
<div style={{ width: "5%", }}>{item.correct_answer.replace("option ", "")}</div> */}


                  </section>
                ))}


                {/* <a href="https://api.epsexam.com/wp-content/uploads/2023/03/ubt-cbt-eps-1-1.jpeg" download="w3logo">
<img src="https://api.epsexam.com/wp-content/uploads/2023/03/ubt-cbt-eps-1-1.jpeg" alt="W3Schools" width="104" height="142"/>
</a> */}




                <div style={{ pageBreakBefore: "always", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  {Object.values(QuestionsData).map((oneQuestionData, index) => (
                    <section key={index} style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}
                      onClick={() => {
                      }}
                    >
                      {(oneQuestionData.question_description_type == "audio" ||
                        oneQuestionData.question_media_type == "audio") &&
                        <div>
                          <div style={{ cursor: "pointer" }} onClick={() => {
                          }}>
                            <u><b>Question No {oneQuestionData.question_number}.</b></u>
                            <br />
                            <u><b>Question Audio Link</b></u>
                            <br />
                            {oneQuestionData.question_description.includes(".mp3") &&
                              // oneQuestionData.question_description
                              <div style={{ cursor: "pointer" }} onClick={() => {
                              }}>
                                <a href={oneQuestionData.question_description} download={`${Title}-${oneQuestionData.question_number}`}>
                                  <div style={{ position: "absolute" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                      <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                    </div>
                                  </div>
                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </a>
                              </div>
                            }
                            {oneQuestionData.question_media.includes(".mp3") &&
                              // oneQuestionData.question_media
                              <div style={{ cursor: "pointer" }} onClick={() => {
                              }}>
                                <a href={oneQuestionData.question_media} download={`${Title}-${oneQuestionData.question_number}`}>
                                  <div style={{ position: "absolute" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                      <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                    </div>
                                  </div>
                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </a>
                              </div>
                            }




                            {oneQuestionData.answer_media_type == "audio" &&
                              <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                                <br />
                                <u><b>Answer Audio Link</b></u>
                                <br />
                                <div style={{ display: "flex" }}>
                                  A.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_1} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  B. <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_2} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  C.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_3} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  D.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_4} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                </div>
                              </div>
                            }


                          </div>
                        </div>
                      }
                    </section>
                  ))}
                </div>


                <div style={{ pageBreakBefore: "always", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  <br />
                  <u><b>Answers</b></u>
                  <br />
                  <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
                    <div className="row" >
                      {Object.values(QuestionsData).map((oneQuestionData, index) => (
                        <section key={index} style={{ width: "10%", border: "1px solid black", }}>
                          <div className="row">
                            <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
                            <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>


      <div style={{
        margin: "20px",
        display: "none"
      }}>
        <div ref={StudentExportRef2} style={{ margin: "20px" }}>
          <h2>{Title}</h2>
          <div className="row">
            {/* <div className="col-1"></div> */}
            <div className="col-12 form-view mb-3" style={{ padding: "20px" }}>
              <div style={{ overflowX: "scroll", pageBreakBefore: "auto", }}>
                {Object.values(QuestionsData).map((oneQuestionData, index) => (
                  <section key={index} style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }} >
                    <div>
                      <div
                        style={{
                          border: "1px solid #999",
                          margin: "3px",
                          padding: 0
                        }}>
                        <div className="col-md-12" >
                          <div>
                            <div
                              style={{ padding: "3px 0 0 0" }}
                            >
                              <h3 style={{ fontSize: 12, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question}
                              </h3>
                              <h3 style={{ fontSize: 12, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                {oneQuestionData.question_number}.
                              </h3>
                            </div>
                            <div className="question-description-2" style={{ marginLeft: "20px" }}>
                              {oneQuestionData.question_description_type == "text" &&
                                <div style={{
                                  fontSize: "12px",
                                  padding: "0 0 3px"
                                }}>
                                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                </div>
                              }
                              {oneQuestionData.question_description_type == "image" &&
                                <>
                                  <div style={{
                                    padding: "2px", textAlign: "center",
                                    // width: "392px",
                                    // width: "100%",
                                  }}
                                    onClick={() => {
                                    }}
                                  >
                                    <img
                                      src={oneQuestionData.question_description}
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                        objectFit: 'contain',
                                        padding: "2px",
                                        border: "0.5px solid #000",
                                      }}
                                    />
                                  </div>
                                </>
                              }
                              {oneQuestionData.question_description_type == "audio" &&
                                <div>
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <div style={{ position: "absolute" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "30px", height: "30px" }}>
                                        <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                                      </div>
                                    </div>
                                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                      style={{ width: "30px", height: "30px" }}
                                    />
                                  </div>


                                </div>
                              }
                            </div>


                            {oneQuestionData.question_media !== "" &&
                              <div className="question-description col-10">
                                {oneQuestionData.question_media_type == "text" &&
                                  <div style={{
                                    border: "0.5px solid #777",
                                    padding: "3px ",
                                    justifyContent: 'center',
                                    display: "flex",
                                    borderRadius: "5px"
                                  }}>
                                    <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                  </div>
                                }


                                {oneQuestionData.question_media_type == "image" &&
                                  <div style={{ textAlign: "center" }}
                                    onClick={() => {
                                    }}
                                  >
                                    <img
                                      src={oneQuestionData.question_media}
                                      style={{
                                        height: "100px",
                                        width: "100px",
                                        // width: "100%",
                                        padding: "2px",
                                        objectFit: 'contain',
                                        border: "0.5px solid #000"
                                      }}
                                    />
                                  </div>
                                }
                                {oneQuestionData.question_media_type == "audio" &&
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <div style={{ position: "absolute" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "30px", height: "30px" }}>
                                        <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                                      </div>
                                    </div>
                                    <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                      style={{ width: "30px", height: "30px" }}
                                    />


                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </div>


                        <div className="col-md-12" style={{ padding: "0" }}>
                          <div
                            className="row"
                            style={{
                              // border: "1px solid #999",
                              margin: "10px"
                            }} >
                            <AnswerBlock2 index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock2 index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock2 index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} />
                            <AnswerBlock2 index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} />


                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ width: "5%", display: "inline", textAlign: "center" }}>{item.question_number}</div>
<div style={{ width: "30%" }}>{item.question}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_1 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_2 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_3 : item.answer_media_type}</div>
<div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_4 : item.answer_media_type}</div>
<div style={{ width: "5%", }}>{item.correct_answer.replace("option ", "")}</div> */}


                  </section>
                ))}


                {/* <a href="https://api.epsexam.com/wp-content/uploads/2023/03/ubt-cbt-eps-1-1.jpeg" download="w3logo">
<img src="https://api.epsexam.com/wp-content/uploads/2023/03/ubt-cbt-eps-1-1.jpeg" alt="W3Schools" width="104" height="142"/>
</a> */}




                <div style={{ pageBreakBefore: "always", display: "none", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  {Object.values(QuestionsData).map((oneQuestionData, index) => (
                    <section key={index} style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}
                      onClick={() => {
                      }}
                    >
                      {(oneQuestionData.question_description_type == "audio" ||
                        oneQuestionData.question_media_type == "audio") &&
                        <div>
                          <div style={{ cursor: "pointer" }} onClick={() => {
                          }}>
                            <u><b>Question No {oneQuestionData.question_number}.</b></u>
                            <br />
                            <u><b>Question Audio Link</b></u>
                            <br />
                            {oneQuestionData.question_description.includes(".mp3") &&
                              // oneQuestionData.question_description
                              <div style={{ cursor: "pointer" }} onClick={() => {
                              }}>
                                <a href={oneQuestionData.question_description} download={`${Title}-${oneQuestionData.question_number}`}>
                                  <div style={{ position: "absolute" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                      <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                    </div>
                                  </div>
                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </a>
                              </div>
                            }
                            {oneQuestionData.question_media.includes(".mp3") &&
                              // oneQuestionData.question_media
                              <div style={{ cursor: "pointer" }} onClick={() => {
                              }}>
                                <a href={oneQuestionData.question_media} download={`${Title}-${oneQuestionData.question_number}`}>
                                  <div style={{ position: "absolute" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                      <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                    </div>
                                  </div>
                                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                </a>
                              </div>
                            }




                            {oneQuestionData.answer_media_type == "audio" &&
                              <div style={{ pageBreakInside: "avoid", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                                <br />
                                <u><b>Answer Audio Link</b></u>
                                <br />
                                <div style={{ display: "flex" }}>
                                  A.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_1} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  B. <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_2} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  C.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_3} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                  D.
                                  <div style={{ cursor: "pointer" }} onClick={() => {
                                  }}>
                                    <a href={oneQuestionData.option_4} target="_blank">
                                      <div style={{ position: "absolute" }}>
                                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                                          <i className="fa fa-play " style={{ fontSize: "12px", color: "#000" }}></i>
                                        </div>
                                      </div>
                                      <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                        style={{ width: "50px", height: "50px" }}
                                      />
                                    </a>
                                  </div>
                                  &nbsp;
                                  &nbsp;
                                </div>
                              </div>
                            }


                          </div>
                        </div>
                      }
                    </section>
                  ))}
                </div>


                <div style={{ pageBreakBefore: "always", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  <br />
                  <u><b>Answers</b></u>
                  <br />
                  <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
                    <div className="row" >
                      {Object.values(QuestionsData).map((oneQuestionData, index) => (
                        <section key={index} style={{ width: "10%", border: "1px solid black", }}>
                          <div className="row">
                            <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
                            <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>

      <div style={{
        margin: "20px",
        display: "none"
      }}>
        <div ref={StudentExportRef3} style={{}}>
          <h2>{Title}</h2>
          <div className="row">
            {/* <div className="col-1"></div> */}
            <div className="col-12 form-view mb-3" style={{ padding: "5px 10px" }}>


              <div style={{ overflowX: "scroll", pageBreakBefore: "auto", }}>


                {Object.values(QuestionsData).map((oneQuestionData, index) => {
                  const lower = oneQuestionData.question.split(" ")[0].split("~")[0].replace(/\D/g, "");
                  // const higher = oneQuestionData.question.split(" ")[0].split("~")[1].replace(/\D/g, "");

                  return (
                    <section key={index} style={{
                      pageBreakInside: "avoid",
                      display: "block",
                      cursor: "pointer", backgroundColor: "#fff", padding: "3px 0"
                    }}
                      onClick={() => {
                      }}
                    >
                      <div>
                        <div
                          // className="row"
                          style={{
                            // border: "1px solid #999",
                            margin: "2px",
                            padding: 0
                          }}>
                          <div className="col-md-12" >
                            <div style={{}}>
                              <div
                                style={{ padding: "3px 0 0 0" }}
                              >

                                {/* {lower} {higher} {oneQuestionData.question_number} */}
                                {lower == oneQuestionData.question_number &&
                                  <h3 style={{ fontSize: "12px", fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                    {oneQuestionData.question}
                                  </h3>
                                }
                                <h3 style={{ fontSize: '10px', fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
                                  {oneQuestionData.question_number}.
                                </h3>
                              </div>


                              <div className="question-description ">
                                {oneQuestionData.question_description_type == "text" &&
                                  <div style={{
                                    fontSize: "10px",
                                    padding: "0 0 10px"
                                  }}>
                                    <div style={{ fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}></div>
                                  </div>
                                }
                                {oneQuestionData.question_description_type == "image" &&
                                  <>
                                    <div style={{ padding: "2px", textAlign: "center", }}
                                      onClick={() => {
                                      }}
                                    >
                                      <img
                                        src={oneQuestionData.question_description}
                                        style={{
                                          height: "70px",
                                          // width: "150px",
                                          objectFit: 'contain',
                                          padding: "2px",
                                          border: "0.5px solid #000",
                                        }}
                                      />
                                    </div>
                                  </>
                                }
                                {oneQuestionData.question_description_type == "audio" &&
                                  <div>
                                    <a href={oneQuestionData.question_description} download={`${Title}-${oneQuestionData.question_number}`}>
                                      <div style={{ cursor: "pointer" }} onClick={() => {
                                      }}>
                                        {/* <div style={{ position: "absolute" }}>
<div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
<i className="fa fa-play " style={{ fontSize: "12px" }}></i>
</div>
</div> */}
                                        <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                          style={{ width: "20px", height: "20px" }}
                                        />
                                      </div>
                                    </a>
                                  </div>
                                }
                              </div>


                              {oneQuestionData.question_media !== "" &&
                                <div className="question-description col-10">
                                  {oneQuestionData.question_media_type == "text" &&
                                    <div style={{
                                      border: "0.5px solid #000",
                                      padding: "5px ",
                                      justifyContent: 'center',
                                      display: "flex"
                                    }}>
                                      <div style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: oneQuestionData.question_media.replaceAll('\n', '<br/>') }}></div>
                                    </div>
                                  }


                                  {oneQuestionData.question_media_type == "image" &&
                                    <div style={{ textAlign: "center" }}
                                      onClick={() => {
                                      }}
                                    >
                                      <img
                                        src={oneQuestionData.question_media}
                                        style={{
                                          height: "70px",
                                          // width: "150px",
                                          // width: "100%",
                                          padding: "2px",
                                          objectFit: 'contain',
                                          border: "0.5px solid #000"
                                        }}
                                      />
                                    </div>
                                  }
                                  {oneQuestionData.question_media_type == "audio" &&
                                    <a href={oneQuestionData.question_media} download={`${Title}-${oneQuestionData.question_number}`}>
                                      <div style={{ cursor: "pointer" }} onClick={() => {
                                      }}>
                                        <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                                          style={{ width: "20px", height: "20px" }}
                                        />
                                      </div>
                                    </a>
                                  }
                                </div>
                              }
                            </div>
                          </div>


                          <div className="col-md-12" style={{ padding: "0" }}>
                            <div
                              className="row"
                              style={{
                                // border: "1px solid #999",
                                margin: "10px"
                              }} >
                              <AnswerBlock3 index={1} DataValue={oneQuestionData.option_1} DataType={oneQuestionData.answer_media_type} />
                              <AnswerBlock3 index={2} DataValue={oneQuestionData.option_2} DataType={oneQuestionData.answer_media_type} />
                              <AnswerBlock3 index={3} DataValue={oneQuestionData.option_3} DataType={oneQuestionData.answer_media_type} />
                              <AnswerBlock3 index={4} DataValue={oneQuestionData.option_4} DataType={oneQuestionData.answer_media_type} />


                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )
                })}

                <div style={{ pageBreakBefore: "always", display: "block", cursor: "pointer", backgroundColor: "#fff", padding: "3px 0" }}>
                  <br />
                  <u><b>Answers</b></u>
                  <br />
                  <div style={{ border: "2px solid black", padding: "0px 10px", width: "90%", marginLeft: "10px" }}>
                    <div className="row" >
                      {Object.values(QuestionsData).map((oneQuestionData, index) => (
                        <section key={index} style={{ width: "10%", border: "1px solid black", }}>
                          <div className="row">
                            <div className="col-6" style={{ borderRight: "0.5px solid black", textAlign: "center", padding: "0px 0" }} >{index + 1}</div>
                            <div className="col-6" style={{ padding: "0px 0", textAlign: "center" }}>{oneQuestionData.correct_answer.replace("option ", "")}</div>
                          </div>
                        </section>
                      ))}
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>


        </div>
      </div>

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}



const AnswerBlock = ({ index, DataType, DataValue }) => {
  return (
    <div
      // className="col-6"
      style={{
        display: 'flex',
        padding: "5px 10px",
        borderBottom: "1px solid #999"
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
      >


        <div style={{
          border: "2px solid black",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>


      <div className="passive-option2">
        {DataType == "text" &&
          `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                // height: "180px",
                // objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <div style={{ cursor: "pointer" }} onClick={() => { }}>
              <div>
                <div style={{
                  position: "absolute"
                }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                    <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                  </div>
                </div>
                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </div>


  )
}




const AnswerBlock2 = ({ index, DataType, DataValue }) => {
  return (
    <div
      className="col-6"
      style={{
        display: 'flex',
        padding: "5px 10px",
        // borderBottom: "1px solid #999"
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
      >


        <div style={{
          border: "2px solid black",
          borderRadius: "14px",
          width: "20px",
          height: "20px", display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>


      <div className="passive-option2">
        {DataType == "text" &&
          `${DataValue}`
        }
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                width: "120px",
                // height: "180px",
                // objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <div style={{ cursor: "pointer" }} onClick={() => { }}>
              <div>
                <div style={{
                  position: "absolute"
                }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                    <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                  </div>
                </div>
                <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </div>


  )
}



const AnswerBlock3 = ({ index, DataType, DataValue }) => {
  return (
    <div
      className={DataType == "text" ? "col-6" : "col-3"}
      style={{
        display: 'flex',
        padding: "5px 10px",
        // borderBottom: "1px solid #999"
      }}
    >
      <div style={{ marginRight: "5px", cursor: "pointer" }}
      >


        <div style={{
          border: "2px solid black",
          borderRadius: "14px",
          width: "20px",
          height: "20px",
          display: "flex", justifyContent: "center", alignContent: "center",
          fontSize: "12px", fontWeight: "bold"
        }}>
          &nbsp;{index}&nbsp;
        </div>
      </div>


      <div className="passive-option2">
        <div style={{ fontSize: "10px" }}>
          {DataType == "text" &&
            `${DataValue}`
          }
        </div>
        {DataType == "image" &&
          <>
            <img
              src={DataValue}
              style={{
                // width: "120px",
                height: "50px",
                // objectFit: 'contain',
                // border: "0.5px solid #000"
              }}
            />
          </>
        }
        {DataType == "audio" &&
          <div>
            <a href={DataValue} target="_blank">
              <div style={{ cursor: "pointer" }} onClick={() => { }}>
                <div>
                  {/* <div style={{
                  position: "absolute"
                }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "50px", height: "50px" }}>
                    <i className="fa fa-play " style={{ fontSize: "12px" }}></i>
                  </div>
                </div> */}
                  <img src="https://api.epsexam.com/wp-content/uploads/2022/09/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    </div>


  )
}